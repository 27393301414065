import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import DashboardData from '../components/DashboardData';
import PleaseWait from '../components/PleaseWait';
import AthleteProfile from '../components/AthleteProfile';
import PointsTable from '../components/PointsTable';
import ChallengeInfo from '../components/ChallengeInfo';
import useFetch from '../hooks/useFetch';

const Dashboard = (props) => {

  const navigate = useNavigate();
  const {
    data,
    challengeData,
    loading,
    logout,
  } = props;

  const isLoggedIn = data ? true : false && Boolean(data?.athleteId);

  const {
    data: leaderboardData,
    isLoading: leaderboardLoading,
  } = useFetch('v1/scores/challenge', [process.env.REACT_APP_CHALLENGE_ID], {});

  useEffect(() => {
    window.sessionStorage.setItem('loaderBoard', JSON.stringify(leaderboardData));
  });

  if (isLoggedIn === false) {
    navigate('/');
  }


  if (loading ? true : !!leaderboardLoading) {
    return (
      <PleaseWait />
    );
  }

  if (leaderboardData && leaderboardData.findIndex((e) => e.athleteId === data.athleteId) === -1) {
    logout();
    navigate('/');
  }

  return (
    <VStack spacing='1' width='100%' height='100%'>
      <ChallengeInfo data={data} challengeData={challengeData} logout={{ logout }} />
      <Card width='100%' height='100%'>
        <CardHeader gap='1'>
          <Stack divider={<StackDivider />} spacing='6'>
            <AthleteProfile data={data} challengeData={challengeData} leaderboardData={leaderboardData} />
          </Stack>
        </CardHeader>
        <CardBody textAlign='center'>
          <DashboardData data={data} challengeData={challengeData} leaderboardLoading={leaderboardLoading}
                         leaderboardData={leaderboardData} />);
        </CardBody>
      </Card>
      <PointsTable />
    </VStack>
  );
};

export default Dashboard;
