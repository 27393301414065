import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  HStack,
  Image,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment/moment';
import React from 'react';


const ChallengeInfo = (props) => {

  const { data, challengeData, logout } = props;

  return (
    <VStack spacing='1' width='100%' height='100%'>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        width={'100%'}
      >
        <Image
          objectFit='cover'
          maxW={{ base: '100%', sm: '280px' }}
          maxH={{ base: '100%', sm: '96px' }}
          src={challengeData?.logoUrl}
          alt={challengeData?.name}
        />
        <Stack>
          <CardBody>
            <Heading size='md'>{challengeData?.name}</Heading>
            <HStack mt='4'>
              <Badge>{moment(new Date(challengeData?.startDate)).utc(false).format('DD MMM YYYY')}</Badge>
              <Badge>To</Badge>
              <Badge>{moment(new Date(challengeData?.endDate)).utc(false).format('DD MMM YYYY')}</Badge>
              <Badge>Participants: {isNaN(challengeData?.totalRegisteredAthletes) ? 0 : challengeData?.totalRegisteredAthletes}</Badge>
            </HStack>
          </CardBody>
        </Stack>
        <StackDivider />
        <CardFooter>
          <Button
            fontFamily={'heading'}
            bgGradient='linear(to-r, red.600,blue.600)'
            color={'white'}
            _hover={{
              bgGradient: 'linear(to-l, red.600,blue.600)',
              boxShadow: 'xl',
            }} onClick={(e) => {
            e.stopPropagation();
            logout();
          }}>
            Logout
          </Button>
        </CardFooter>
      </Card>

    </VStack>
  );
};

export default ChallengeInfo;