import React from 'react';
import RegistrationForm from '../components/RegistrationForm';
import PleaseWait from '../components/PleaseWait';
import { Navigate } from 'react-router-dom';

const Home = (props) => {

  const {
    data,
    loading,
    error,
    getAuth,
    logout,
    registrationData,
    onChangeRegistrationData,
  } = props;

  if (error) {
    return (
      <Navigate to='/' />
    );
  }

  const isLoggedIn = Boolean(data?.athleteId);

  if (loading) {
    return (<PleaseWait />);
  }

  if (isLoggedIn) {
    return (
      <Navigate to='/dashboard' />
    );
  }

  return (
    <RegistrationForm data={data} loading={loading} error={error} getAuth={getAuth} logout={logout}
                      registrationData={registrationData} onChangeRegistrationData={onChangeRegistrationData} />
  );
};

export default Home;