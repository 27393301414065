import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Card,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import PleaseWait from './PleaseWait';

const AthleteProfile = (props) => {

  const { data, challengeData, leaderboardData } = props;

  if (challengeData && leaderboardData) {
    let avatar = (
      <Avatar name={data?.firstName} src={data?.profilePicture} size='xl'>
      </Avatar>
    );
    if (Date.now() >= challengeData?.startDate && Date.now() <= challengeData?.endDate) {
      avatar = (
        <Avatar name={data?.firstName} src={data?.profilePicture} size='xl'>
          <AvatarBadge borderColor='tomato' borderWidth='0.1em' bg='tomato' boxSize='1.25em' color={'white'}>
            {leaderboardData[leaderboardData?.findIndex((e) => e.athleteId === data.athleteId)]?.rank}
          </AvatarBadge>
        </Avatar>);
    }
    let scoreStat = (
      <Badge>
        Points: {isNaN(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalScore) ? 0 : Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalScore)}
      </Badge>
    );
    if (challengeData?.scoreType === 'GOAL') {
      scoreStat = (
        <Badge>
          Progress: {isNaN(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalScore) ? '0%' : Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalScore) + '%'}
        </Badge>
      );
    }
    return (
      <Card direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            variant='outline'
            width={'100%'}>
        <CardHeader>
          <Flex spacing='4'>
            <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
              {avatar}
              <Box>
                <Heading size='sm'>{data?.firstName} {data?.lastName}</Heading>
                <Text>{data?.city}, {data?.state}</Text>
                <HStack mt='4'>
                  <Badge>Distance
                    (kms): {Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalUncappedDistance)}</Badge>
                  <Badge>Elevation
                    (mts): {Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalUncappedElevation)}</Badge>
                  <Badge>Duration
                    (hrs): {Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalUncappedMovingTime)}</Badge>
                  {scoreStat}
                </HStack>
                <Text color={'red.800'} fontSize={{ base: 'sm', sm: 'md' }}>
                  * Statistics shown here are overall statistics for the challenge period.
                </Text>
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
      </Card>
    );
  } else {
    return (<PleaseWait />);
  }
};

export default AthleteProfile;